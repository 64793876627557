import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Card, Typography } from '@material-ui/core';
import { COLORS } from '../../constants/styleConstants';
import ContentListItem from '../contentListItem/ContentListItem';
import title from '../../utils.js';

const useStyles = makeStyles(() => ({
  root: {
    width: 260,
    height: 460,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLORS.white,
    borderRadius: 30,
    boxShadow: '0 11px 20px 6px rgba(0, 0, 0, 0.12)',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${COLORS.mischka}`,
    width: '100%',
    height: 200,
    minHeight: 200,
    padding: 0,
    margin: 0,
  },
  logo: {
    width: 'auto',
    height: 180,
  },
  content: {
    padding: '24px 35px 47px 35px',
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontWeight: 'bold',
  },
  status: {
    width: '100%',
    height: 22,
    marginTop: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
  },
  statusText: {
    fontWeight: 'bold',
  },
  brandCardContentItemContainer: {
    gridGap: 10,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
  },
}));

const BrandCard = (props) => {
  const classes = useStyles();
  const {
    color,
    logo,
    retailerRelationship,
    status,
    liveData,
    storeLocator,
    fulfillment,
    marketRetailer,
    rideBooking,
    scheduler,
    isFulfillmentPlus,
    warranty,
  } = props;
  const noWrap = true;
  const hideImg = (event) => {
    // eslint-disable-next-line no-param-reassign
    event.target.style.display = 'none';
  };

  const isApproved = (channelStatus = '') => {
    const statusText = channelStatus.replace(/\s/g, '').toLowerCase();
    if (statusText === 'approveddealer') {
      return true;
    }
    return false;
  };

  const getStatusBgColor = (dealerStatus = '') => {
    let bgcolor = COLORS.alto;
    let textColor = COLORS.black;

    const statusText = dealerStatus.replace(/\s/g, '').toLowerCase();
    if (statusText === 'notadealer') {
      bgcolor = COLORS.cosmos;
      textColor = COLORS.redBerry;
    } else if (statusText === 'dealerstatuspending') {
      bgcolor = COLORS.peach;
      textColor = COLORS.indochine;
    } else if (statusText === 'approveddealer') {
      bgcolor = COLORS.iceCold;
      textColor = COLORS.funGreen;
    }

    return { bgcolor, color: textColor };
  };

  const statusText = status.replace(/dealer/ig, title(retailerRelationship));
  const statusColors = getStatusBgColor(status);
  const liveDataText = 'Live Data';
  const getIcon = (feature, key) => {
    const featureIsFulfillment = key === 'fulfillment';
    const featureIsPmr = key === 'pmr';
    if (featureIsFulfillment && isFulfillmentPlus) {
      if (feature.enabled && feature.available && feature.dependencies_enabled) {
        return 'tick';
      }

      if (feature.enabled && feature.available && !feature.dependencies_enabled) {
        return 'exclamation';
      }

      if (feature.available && !feature.enabled) {
        return 'cross';
      }
    } else {
      if (!isApproved(status) && feature.available) {
        return 'circle';
      }

      if (feature.available && feature.enabled && feature.dependencies_enabled) {
        return 'tick';
      }

      if (feature.available && feature.enabled && !feature.dependencies_enabled) {
        return featureIsPmr ? 'cross' : 'exclamation';
      }

      if (feature.available && !feature.enabled) {
        return 'cross';
      }
    }

    return '';
  };

  return (
    <Card className={classes.root} elevation={0}>
      <Box className={classes.logoContainer} bgcolor={color}>
        {logo && <img src={logo} alt={logo} className={classes.logo} onError={hideImg} />}
      </Box>
      <Box className={classes.content}>

        <Box className={classes.status} bgcolor={statusColors.bgcolor}>
          <Typography
            color={statusColors.color}
            noWrap={noWrap}
            variant="subtitle2"
            className={classes.statusText}
            title={statusText}
          >
            {statusText}
          </Typography>
        </Box>
        <Box className={classes.brandCardContentItemContainer}>
          {fulfillment.available && (
          <ContentListItem
            icon={getIcon(fulfillment, 'fulfillment')}
            content="Receive Fulfillment Orders"
          />
          )}
          {scheduler.available && (
          <ContentListItem
            icon={getIcon(scheduler)}
            content="Receive Service Appointments"
          />
          )}
          {storeLocator.available && (
          <ContentListItem
            icon={getIcon(storeLocator)}
            content="Display on Locations Map"
          />
          )}
          {liveData.available && (
          <ContentListItem
            icon={getIcon(liveData)}
            content={liveDataText}
          />
          )}
          {marketRetailer.available && (
          <ContentListItem
            icon={getIcon(marketRetailer, 'pmr')}
            content="Affiliate Program"
          />
          )}
          {rideBooking.available && (
          <ContentListItem
            icon={getIcon(rideBooking)}
            content="Receive Test Ride Bookings"
          />
          )}
          {warranty.available && (
          <ContentListItem
            icon={getIcon(warranty)}
            content="Receive Warranty Appointments"
          />
          )}
        </Box>
      </Box>
    </Card>
  );
};

BrandCard.propTypes = {
  color: PropTypes.string,
  logo: PropTypes.string,
  retailerRelationship: PropTypes.string,
  status: PropTypes.string,
  fulfillment: PropTypes.shape({
    value: PropTypes.string,
    available: PropTypes.bool,
    enabled: PropTypes.bool,
  }).isRequired,
  storeLocator: PropTypes.shape({
    available: PropTypes.bool,
    enabled: PropTypes.bool,
  }).isRequired,
  marketRetailer: PropTypes.shape({
    available: PropTypes.bool,
    enabled: PropTypes.bool,
  }).isRequired,
  rideBooking: PropTypes.shape({
    available: PropTypes.bool,
    enabled: PropTypes.bool,
  }).isRequired,
  liveData: PropTypes.shape({
    available: PropTypes.bool,
    enabled: PropTypes.bool,
    qoh: PropTypes.string,
  }).isRequired,
  scheduler: PropTypes.shape({
    available: PropTypes.bool,
    enabled: PropTypes.bool,
  }).isRequired,
  isFulfillmentPlus: PropTypes.bool,
  warranty: PropTypes.shape({
    available: PropTypes.bool,
    enabled: PropTypes.bool,
  }).isRequired,
};

BrandCard.defaultProps = {
  color: '#ffffff',
  logo: '',
  retailerRelationship: 'dealer',
  status: 'Dealer Status N/A',
  isFulfillmentPlus: false,
};

export default BrandCard;
