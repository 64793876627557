import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import Grid from '../grid/Grid';
import { COLORS, FONT_SIZE, FONT_STYLE } from '../../constants/styleConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 26,
    width: '100%',
  },
  gridContainer: {
    marginTop: 25,
    width: '100%',
  },
  gridHeaderLabel: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_12),
    fontWeight: FONT_STYLE.bold,
    color: COLORS.black,
  },
  gridHeadingWrapper: {
    textAlign: 'center',
  },
  preferredMarketRetailerCompensationRuleTypeRowLabel: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_14),
    color: COLORS.black,
    padding: '3px 23px',
    display: 'inline-block',
  },
  gridRowLabelWrapper: {
    textAlign: 'center',
  },
  finePrint: {
    marginTop: '20px',
  },
}));

const PreferredMarketRetailerSection = (props) => {
  const classes = useStyles();
  const { data, preferredMarketRetailerBadge } = props;
  const noWrap = true;

  const gridColumns = [
    {
      label: 'Badge for eligibility',
      labelClassName: classes.gridHeaderLabel,
      width: '0.8fr',
    },
    {
      label: 'Product type',
      labelClassName: classes.gridHeaderLabel,
      wrapperClassName: classes.gridHeadingWrapper,
      width: '0.5fr',
    },
    {
      label: 'Qualified order radius',
      labelClassName: classes.gridHeaderLabel,
      wrapperClassName: classes.gridHeadingWrapper,
      width: '0.5fr',
    },
    {
      label: 'Compensation *',
      labelClassName: classes.gridHeaderLabel,
      wrapperClassName: classes.gridHeadingWrapper,
      width: '0.5fr',
    },
  ];

  const getCompensation = (item) => {
    if (item.flat_amount) {
      return `$${item.flat_amount.toFixed(2)}`;
    }

    return `${parseFloat(item.order_percentage * 100, 10)}%`;
  };

  const convertToGridDataFormat = (pmrCompRules) => pmrCompRules.map(
    (item) => ([
      {
        label: preferredMarketRetailerBadge,
        labelClassName: classes.preferredMarketRetailerCompensationRuleTypeRowLabel,
      },
      {
        label: item.name,
        labelClassName: classes.preferredMarketRetailerCompensationRuleTypeRowLabel,
        wrapperClassName: classes.gridRowLabelWrapper,
      },
      {
        label: `${item.eligible_radius} miles`,
        labelClassName: classes.preferredMarketRetailerCompensationRuleTypeRowLabel,
        wrapperClassName: classes.gridRowLabelWrapper,
      },
      {
        label: getCompensation(item),
        labelClassName: classes.preferredMarketRetailerCompensationRuleTypeRowLabel,
        wrapperClassName: classes.gridRowLabelWrapper,
      },
    ]),
  );

  return (
    <>
      <Box className={classes.root}>
        <Typography
          noWrap={noWrap}
          variant="h2"
        >
          Affiliate Program
        </Typography>
      </Box>
      <Box className={classes.gridContainer}>
        <Grid columns={gridColumns} data={convertToGridDataFormat(data)} />
      </Box>
      {/* <div className={classes.finePrint}>
        * Per Product in Order
      </div> */}
    </>
  );
};

PreferredMarketRetailerSection.propTypes = {
  data: PropTypes.string.isRequired,
  preferredMarketRetailerBadge: PropTypes.string.isRequired,
};

export default PreferredMarketRetailerSection;
